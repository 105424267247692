angular
   .module("vdsr")
   .controller("loginController", [
      "$scope",
      "$http",
      "$window",
      "$location",
      'countdown',
      'apiauth',
      'taskWrap',
      /**
       * 
       * @param {angular.IScope} $scope 
       * @param {angular.IHttpService} $http 
       * @param {*} $window 
       * @param {*} $location 
       * @param {countdownService} countdown 
       * @param {apiAuthService} apiauth
       * @param {taskWrap} taskWrap
       */
      function($scope, $http, $window, $location, countdown, apiauth, taskWrap) {

         //$scope.selectedMethod = apiauth.getMethodInfo('app');

         $scope.comingFromPwdReset = !!$location.search().pwdReset;

         let bag = {
            logoObj: $window.stw.globals.companyImages.logos.loginlogo,
            bgObj: $window.stw.globals.companyImages.logos.loginBG,
            imageCredit: $window.stw.globals.companyImages.logos.loginBG.imageCredit,
            imageCreditURL: $window.stw.globals.companyImages.logos.loginBG.imageCreditURL,
            imageCreditColor: $window.stw.globals.companyImages.logos.loginBG.imageCreditColor,
            /** @type {'password' | 'text'} */
            pwType: 'password',
            /**@type {{method?: TFMethodName | '', codeExpirationInSeconds?: number, counter?: CountdownInstance}} */
            twoFactor: {method:""},
            login: {},
            expandedAlternates: false,
            sendingCodeTo: "",
            methods: apiauth.getAllMethodInfo(),
            currentState: [],
            isMFAVerified: false,
            loginFailed: false
         };

         console.log('bag: ', bag);
         var bgElem = angular.element(document.querySelector('.stw-login-bg'));
         bgElem.css('background-image', 'url('+bag.bgObj.url+')');

         $scope.bag = bag;

         /// Begin: two-factor related 
         $scope.comingFromTwoFactorExpired = !!$location.search().twoFactorCodeExpired;
         $scope.twoFactorErrorMessage = '';


         /**
           @typedef {Object} TwoFactorResponse
           @property {TFMethodName} method
           @property {number?} codeExpirationInSeconds
           @property {CountdownInstance?} counter
           @property {TFMethodName[]} alternateMethods
           @property {string} phoneHidden
           @property {string} emailHidden
         */
         /**
          * @param {TwoFactorResponse} twoFactor 
          */
         function processTwoFactor(twoFactor) {
            //Object.assign($scope.bag.twoFactor, twoFactor);
            $scope.bag.twoFactor.method = twoFactor.method
            $scope.bag.twoFactor.alternateMethods = twoFactor.alternateMethods;
            if (twoFactor.codeExpirationInSeconds) {
               twoFactor.counter = countdown.create(
                  twoFactor.codeExpirationInSeconds, 
                  () => {
                     //$window.location.href = "/login?twoFactorCodeExpired";
                  }
               );
            }
         }

         $scope.viewPassword = () => {
            $scope.bag.pwType = $scope.bag.pwType == 'password' ? 'text' : 'password';
         }

         /**
          * Calls API to test the input 2fa code, if successful, login and view dashboard, otherwise display error
          * @param {string} code
          * @param {TFMethodName} method 
          */
         $scope.verifyCode = function(code, method) {
            $scope.twoFactorErrorMessage = ''; 
            let rx = /\s*\d{3}\s*\d{3}\s*/;
            if (!rx.test(code)) {
               $scope.twoFactorErrorMessage = 'Code must consist of a 6-digit number';
               return;
            }
            $scope.bag.verifyingTwoFactorCode = true;
            let data = $scope.bag.login; 
            data.userToken = code;
            data.method = method;
            
            $http.post('/login-2f', data)
            .then(result => {
               $window.location = result.data.data.route;
            })
            .catch(err => {
               $scope.twoFactorErrorMessage = err.data.error.message;
            })
            ['finally'](function(){
               $scope.bag.verifyingTwoFactorCode = false;
            })
            
         };


         $scope.send2fCode = function(method){
            $scope.bag.sendingCodeTo = method
            $scope.twoFactorErrorMessage = '';
            $http({
               url: "/send2fCode",
               method: "post",
               data: {
                  method,
                  email: $scope.bag.login.email
               }
            })
            .then(function(d){
               let result = d.data;
               if (result.data.twoFactor) {
                  processTwoFactor(result.data.twoFactor);
               }
            })
            .catch(function(r){
               let res = r.data;
               $scope.comingFromPwdReset = false;
               $scope.comingFromTwoFactorExpired = false;
               $scope.bag.loginFailed = true;
               $scope.errorMessage = res.error.message;
            })
            .finally(function(){
               $scope.bag.sendingCodeTo = ""
               $scope.bag.expandedAlternates = false;
            });
         }

         $scope.mfaSetupLogin = function(){
            $window.location = '/'
         }

         /**
          * 
          * @param {TFMethodName} newMethod 
          */
         $scope.useAlternateMethod = function(newMethod){
            /** @type {TwoFactorResponse} */
            let curTwoFactor = $scope.bag.twoFactor;
            let alternateMethods = curTwoFactor.alternateMethods.filter(m => m !== newMethod).concat(curTwoFactor.method)
            $scope.twoFactorErrorMessage = '';
            if(newMethod === 'app'){
               processTwoFactor({
                  method: "app",
                  alternateMethods,
                  phoneHidden: curTwoFactor.phoneHidden,
                  emailHidden: curTwoFactor.emailHidden,
                  codeExpirationInSeconds: null,
                  counter: null
               });
            } else {
               $scope.send2fCode(newMethod);
            }
         }

         /**
          * 
          * @param {string} methodName 
          * @param {TFEnableStatus} newStatus 
          */
         $scope.verified = function(methodName, newStatus){
            updateCurrentState(newStatus);
         }


         /**
          * 
          * @param {TFEnableStatus} curState 
          */
         function updateCurrentState(curState){
            if(Array.isArray(curState.enabledMethods)){
               if(curState.enabledMethods.length) $scope.bag.isMFAVerified = true;
               for(let m of $scope.bag.methods){
                  m.isExpanded = false;
                  let enabledMethod = curState.enabledMethods.find(em => em.method === m.name);
                  if(!enabledMethod){
                     m.isEnabled = false;
                     m.isDefault = false;
                     m.createdAt = "";
                  } else {
                     m.isEnabled = true;
                     m.isDefault = !!enabledMethod.isDefault;
                     if(curState.phone && m.name === 'sms'){
                        m.phone = curState.phone;
                        m.createdAt = enabledMethod.createdAt;
                     }
                     if(curState.email && m.name === 'email'){
                        m.email = curState.email;
                        m.createdAt = enabledMethod.createdAt;
                     }
                     if(m.name === 'app'){
                        m.createdAt = enabledMethod.createdAt;
                     }
                  }
               };
            }
         }

         /**
          * Calls login API with password.  In response, if 2fa required, setup UI for 2fa handling
          */
         $scope.loginsub = function() {
            $scope.bag.loginFailed = false;
            console.log("login details: ", $scope.bag.login)
            apiauth.post('/requiresAuthentication', $scope.bag.login )
            .then((res)=>{
               // change screens to two factor setup enforcement
               if(res.requiresTwoFactorSetup){
                  $scope.bag.phone = res.phone || '';
                  $scope.bag.requiresTwoFactorSetup = res.requiresTwoFactorSetup;
                  console.log("requires twofactorsetup")
               // otherwise, do normal login process
               } else {
                  return apiauth.post('/login', $scope.bag.login)
                  .then(function(d) {
                     let result = d;
                     $scope.comingFromPwdReset = false;
                     $scope.comingFromTwoFactorExpired = false;
                     console.log("result.data", result);
                     if (result.twoFactor) {
                        processTwoFactor(result.twoFactor);
                     }
                     else {
                        if (result.valid) {
                           $window.location = result.route;
                        } else {
                           $scope.invalid = true;
                        }
                     }
                  })
                  .catch(function(r) {
                     if(!r){ console.log("error with login post: ", r); return}
                     $scope.comingFromPwdReset = false;
                     $scope.comingFromTwoFactorExpired = false;
                     $scope.bag.loginFailed = true;
                     $scope.errorMessage = r.error.message;
                  });
               }
            })
            .catch(function(r){
               if(r.message){
                  $scope.comingFromPwdReset = false;
                  $scope.comingFromTwoFactorExpired = false;
                  $scope.bag.loginFailed = true;
                  $scope.errorMessage = r.message;
               }
            })
         };

         /**
          * Handle resizing of logos and images
          * @returns 
          */
         $scope.getLogoSizeConstraints = function() {
            var imageTypeObj = $scope.bag.logoObj;
            var props = ["width", "height", "maxWidth", "maxHeight"];
            var style = {};
            angular.forEach(props, function(v) {
               if (imageTypeObj[v]) {
                  var snake_case = v.replace(/([A-Z])/g, "-$1").toLowerCase();
                  style[snake_case] = imageTypeObj[v];// + "px";
               }
            });
            return style;
         };
      }
   ])
   .service("ajax", [
      "$http",
      function($http) {
         function ajax(options) {
            return new Future(function(reject, resolve) {
               if (!options.url) {
                  return reject(new Error("Forgot to put in url string!"));
               }
               $http({
                  method: options.method || "get",
                  url: options.url,
                  data: options.data || ""
               })
				.success(function(d, s, h, c) {
					resolve(d);
				})
				.error(function(d, s, h, c) {
					reject(
					new Error("Request for " + options.url + " failed."),
					d
					);
				});
            });
         }
         ajax.defaultReject = function(e) {
            console.log(e);
         };
         return ajax;
      }
   ]);
